import React from "react";
import { PROJECT_LINK, PREVIEW } from "../../project-config.js";

export const privacyPolicy = `<div class="row staticContent"><h1>Politique de confidentiialité</h1><p>Produits D'Affaires Deluxe limitee offre un service hébergé de traitement de la paie aux petits commerces d'un bout à l'autre du Canada. Nous respectons rigoureusement la vie privée de toutes les personnes qui utilisent notre site Web, nos produits et nos services.</p>

<p>Cette politique de confidentialité, en vigueur au 30 juin 2006, s'applique à tous les renseignements permettant d'identifier nos clients que nous obtenons lorsqu'ils visitent ce site Web. Notre engagement à respecter et protéger les renseignements personnels de nos clients est reconnu. Nous nous efforçons constamment de protéger votre vie privée et d'utiliser des technologies qui vous procurent le plus haut niveau de sécurité en ligne disponible. Soyez assurés que nous demandons des renseignements à nos clients dans le seul but d'améliorer nos relations avec eux.</p>

<h2>Principes directeurs de la confidentialité</h2>
<p>Le type de renseignement que nous recueillons à votre sujet dépend entièrement de l'usage que vous choisissez de faire de nos services. Nous ne demandons que les renseignements nécessaires pour vous donner le meilleur service possible. Exemples :</p>
<ul>
<li>Exactitude : Nous nous engageons à prendre les mesures nécessaires pour que les renseignements recueillis, utilisés et échangés soient exacts et complets.</li>
<li>Sécurité : Nous nous engageons à prendre les mesures de sécurité nécessaire pour protéger les renseignements contre l'accès, l'utilisation et la divulgation non autorisés.</li>
<li>Respect de la loi : Nous nous engageons à recueillir, utiliser et échanger les données en conformité avec les lois en vigueur et avec les obligations contractuelles.</li>
<li>Confidentialité : Nous nous engageons à recueillir, utiliser et échanger des données en conformité avec les normes de confidentialité appropriées.</li>
<li>Respect de la vie privée : Nous nous engageons à l'autoréglementation en regard des attentes quant au respect de la vie privée, conformément à notre politique et aux normes les plus strictes de l'industrie.</li>
<li>Réceptivité : Nous nous engageons à répondre rapidement et adéquatement à toute requête concernant la protection des renseignements personnels.</li>
<li>Formation du personnel : Nous nous engageons à éduquer nos employés sur les pratiques régissant les renseignements et sur la manipulation adéquate et le bon usage des renseignements.</li>
<li>Autovérification : Nous nous engageons à évaluer périodiquement nos activités internes pour nous assurer qu'elles respectent les pratiques, politiques et procédures établies concernant les renseignements.</li>
</ul>
<p>Les énoncés suivants vous aideront à comprendre comment nous recueillons, utilisons et préservons les renseignements que vous nous fournissez par le biais de notre site Web.</p>
<h2>Renseignements personnels</h2>
<p>Chez Produits D'Affaires Deluxe limitee, nous avons l'intention de vous donner le maximum de contrôle sur vos renseignements personnels. En général, vous pouvez visiter notre site Web sans nous dire qui vous êtes ni divulguer le moindre renseignement à votre sujet. Il arrive parfois cependant que nous ayons besoin de renseignements à votre sujet tel que décrit ci-dessous. Si vous nous dites que vous ne voulez pas que ces renseignements servent à entrer en contact avec vous, nous respecterons votre souhait. Notez que nous retraçons les domaines à partir desquels les gens nous visitent. Nous analysons ces données à des fins statistiques et d'étude de tendances, puis nous les supprimons.</p>
<p>Notre engagement envers le respect de votre vie privée est indéfectible. Cet engagement couvre les renseignements que nous recueillons à votre sujet et l'usage que nous en faisons. Nous avons également un énoncé de sécurité qui décrit comment nous protégeons les renseignements délicats.</p>
<h2>Renseignements à votre sujet recueillis sur notre site Web</h2>
<p>Le type de renseignements que nous recueillons à votre sujet dépend entièrement de la façon dont vous utilisez nos services. Nous ne demandons que les renseignements qui nous aideront à mieux vous servir. Par exemple, lorsque vous faites des achats, votre nom, adresse, numéro de téléphone ou adresse électronique et l'information de carte de crédit sont requis pour effectuer l'achat de façon efficace, pour une livraison exacte et pour communiquer avec vous si nécessaire.</p>
<p>Pour les demandes de catalogues ou d'échantillons de produits, nous avons besoin d'un nom et d'une adresse pour acheminer le colis, en plus du nom de la personne à contacter et son numéro de téléphone ou son adresse électronique au cas où nous devrions vérifier l'adresse ou la mettre à jour. Pour les communications par courriel, il ne nous faut que votre adresse électronique.</p>
<p>De temps à autre, il nous arrive d'effectuer des sondages facultatifs ou tenir des concours. Les formulaires de sondage ou de participation peuvent demander de fournir des renseignements de type démographique ou portant sur la personne à contacter ou sur les préférences d'achat. Si vous désirez vous inscrire à notre service de traitement de la paie, le formulaire d'inscription n'exige que les renseignements d'achat. Les autres questions portent sur le secteur d'activité et le logiciel de comptabilité utilisé, et vous êtes libre d'y répondre ou non.</p>
<h2>Relations d'affaires</h2>
<p>Le site Web de Produits D'Affaires Deluxe limitee comporte des liens vers d'autres sites Web. Produits D'Affaires Deluxe limitee n'assume aucune responsabilité quant aux pratiques de confidentialité ou au contenu de ces sites.</p>
<h2>Sondages et concours</h2>
<p>Occasionnellement, Produits D'Affaires Deluxe limitee peut effectuer un sondage ou tenir un concours. Vous êtes libre d'y participer ou non. Nous employons les renseignements recueillis par ces méthodes pour mettre au point de nouveaux produits améliorés et vous les proposer. De plus, ces renseignements peuvent nous aider à vous offrir une visite adaptée de notre site, en vous montrant des produits et services susceptibles d'être utiles à votre entreprise.</p>
<h2>Accès à vos renseignements personnels</h2>
<p>à titre de client estimé de Produits D'Affaires Deluxe limitee, vous avez le droit de consulter vos renseignements personnels et de les faire corriger en cas d'inexactitude. Il vous revient entièrement de décider si vous voulez nous fournir des renseignements personnels en tout ou en partie, ou de nous interdire d'utiliser ou divulguer vos renseignements à certaines fins. Le fait de vous prévaloir de ce droit pourrait cependant nous empêcher de livrer les produits et services demandés.</p>
<h2>Application</h2>
<p>Périodiquement, nos opérations et pratiques commerciales sont examinées pour en vérifier la conformité à la politique de l'entreprise et aux procédures régissant la confidentialité des renseignements. Ces examens sont faits par des vérificateurs internes qui font rapport directement au conseil d'administration de notre société mère, à des cabinets externes de vérification et de comptabilité, et à des responsables de la réglementation du gouvernement. Ces auto-évaluations examinent les mesures de contrôle et de protection concernant les renseignements personnels des consommateurs et qui sont décrites dans notre politique de confidentialité.</p>
<h2>Votre acceptation de ces modalités</h2>
<p>En utilisant ce site Web, vous consentez aux modalités de la politique de confidentialité qui y est affichée. Si vous n'êtes pas d'accord avec cette politique de confidentialité, veuillez ne pas utiliser ce site Web. Nous nous réservons le droit de remplacer, modifier, ajouter ou enlever des parties de cette politique à tout moment, et ce, à notre entière discrétion. Votre usage continu de ce site Web après que des modifications aux modalités de notre politique de confidentialité y aient été affichées signifie que vous acceptez ces modifications.</p>
<h2>Modifications à la politique de confidentialité</h2>
<p>Nous pouvons modifier notre politique de confidentialité à tout moment. Chaque changement à la politique de confidentialité et de sécurité sera affiché sur ce site Web pour que vous soyez informé des renseignements que nous recueillons et de l'usage que nous en faisons, et des dernières nouvelles sur les moyens que nous prenons pour assurer la sécurité de notre site Web.</p>
<h2>Comment déposer une plainte concernant la protection des renseignements personnels</h2>
<p>Vous pouvez déposer une plainte concernant la protection des renseignements personnels en communiquant avec l'agent principal à la protection des renseignements de Produits D'Affaires Deluxe limitee à l'aide d'une des méthodes suivantes :</p>
<p>Par téléphone au 1 800 465-2888, poste 2700. <br/>
Par télécopieur au 1 800 461-3325, à l'attention de l'agent principal à la protection des renseignements <br/>
Par courriel : canadaprivacyofficer@nebs.com <br/>
Par la poste : <br/>
Agent principal à la protection des renseignements <br/>
Produits d'Affaires Deluxe limitée<br/>
330 Cranston Crescent,<br/>
Midland, Ontario 
<br/>L4R 4V9</p>

<h2>Vos options quant à nos communications</h2>
<p>Sur le formulaire d'adhésion, vous pouvez choisir de recevoir diverses communications de notre part. Si, à tout moment, vous désirez cesser de recevoir ces communications (courriels, appels téléphoniques, télécopies, bulletins d'information ou publicité de la part de nos partenaires), vous pouvez nous le faire savoir sur le formulaire d'adhésion. Vous pouvez également choisir de ne plus recevoir ces communications de même que des catalogues et prospectus, à l'aide d'une des méthodes suivantes :</p>
<p>Par téléphone au 1 800 465-2888, poste 2384. <br/>
Par télécopieur au 1 800 461-3325. <br/>
Par courriel : listmgmt@nebs.com <br/>
Par la poste : <br/>
Service de gestion des listes <br/>
Produits d'Affaires Deluxe limitée <br/>
330 Cranston Crescent, <br/>
Midland, Ontario <br/>
L4R 4V9 <br/></p>
<p>Finalement, dans le cadre de notre exploitation, nous échangeons de temps à autre le nom et l'adresse de votre entreprise avec nos partenaires. Si vous ne voulez pas que le nom et l'adresse de votre compagnie soient échangés avec nos partenaires, communiquez avec nous à l'aide d'une des méthodes ci-dessus.</p>
<h2>Protection de vos transactions</h2>
<p>Chez Produits D'Affaires Deluxe limitee, nous utilisons une technologie de pointe pour sécuriser toutes les transactions. Voici quelques-unes des mesures que nous prenons pour vous protéger et protéger les données que vous nous remettez.</p>
<h2>La technologie SSL</h2>
<p>Nous employons la technologie appelée " protocole sécurisé de cryptage " qui chiffre toute donnée transmise entre vous et Produits D'Affaires Deluxe limitee, pourvu que votre navigateur Web puisse prendre la technologie SSL. Les versions récentes de tous les principaux navigateurs Web peuvent prendre cette technologie. Les données chiffrées sont infiniment plus difficiles à décoder que les données ordinaires, ce qui les rend beaucoup moins attirantes pour un pirate informatique. Avant même que vous n'envoyiez vos renseignements à Produits D'Affaires Deluxe limitee, votre navigateur les aura chiffrés. Ils demeureront chiffrés durant toute la transmission pour être ensuite décodés par notre serveur Web une fois reçus. Pour pouvoir utiliser la technologie SSL, nous avons dû solliciter le privilège de faire du commerce électronique et nous soumettre à un rigoureux processus d'approbation afin d'obtenir un certificat numérique. Notre certificat numérique a été délivré par un service de sécurité autorisé après que nous ayons fait la preuve que nous sommes une entreprise honnête. Ce certificat numérique a ensuite été installé sur notre serveur Web pour que nous puissions dorénavant vous faire bénéficier de la sécurité qu'offre la technologie SSL.</p>
<h2>Autres mesures de sécurité sur Internet</h2>
<p>Voici quelques-uns des autres moyens qui vous permettent de savoir si la transaction que vous êtes en train d'effectuer est sûre :</p>
<ul>
<li>La plupart des sites Web de bonne réputation affichent leurs mesures de sécurité bien en évidence.</li>
<li>Une adresse URL sûre commence par https://, tandis qu'une adresse URL n'offrant pas de transactions WEB sûres commence par http://.</li>
<li>eaucoup de navigateurs comportent des indicateurs visuels vous informant qu'une transaction protégée par SSL est en cours. Recherchez une icône montrant une serrure ou une clef entières (une serrure ouverte ou une clef brisée indiquent un environnement non sécurisé).</li>
<li>Certains navigateurs utilisent une boîte de dialogue pour vous signifier que vous avez accédé à un serveur Web sûr.</li>
</ul>
</div>`;

export const returnPolicy = `<div class="row staticContent"><h1>Return Policy</h1><p>If you have a problem with goods received contact the Seller and advise of the problem.  Each seller will  have a different return policy and procedure.</p>

<p>If you view the Store page for the Seller you can learn more about their specific policies.</p>

<p>As your transaction is between you and the seller and all payment goes to the seller the OpenForBusinessMarketplace is not responsible for handling returns.</p>

<p>If you have any questions about our policies please contact us in the contact us section of the OpenForBusinessMarketplace site.</p></div>`;

export const shippingInformation = `<div class="row staticContent"><h1>Shipping Information</h1><p>Each Seller on the OpenForBusinessMarketplace will choose to either offer</p>

<ol>

<li>Curbside pickup at their store or a farmers market or other location</li>

<li>They will ship to you using a carrier they choose.</li>

</ol>

<p>When you place an order you will receive an order confirmation email that confirms where to pickup your order or advises that the order will be shipped.</p>

<p>When the order has been shipped or is ready for pickup you will receive a second order shipping or pickup confirmation email.  Do not travel to the sellers store until you receive the email that advises the order is ready for pickup.</p>

<p>If the order is shipped a tracking number may be included in the shipping confirmation email that you can click on to track your order.</p>

<p>You can also contact the seller directly to confirm delivery or pickup status.</p>

<p>If you have any questions about our policies please contact us in the contact us section of the OpenForBusinessMarketplace site.</p></div>`;

export const termsOfUse = `<div class="row staticContent"><h1>Terms Of Use</h1><p>You can use this site to search the catalogs of Sellers on the platform to find products to purchase.  Accessing the site in methods that implement denial of service attacks or in any other malicious way is not permitted.  Information on this site cannot be scraped to resold or given to other platforms.</p>

<p>All product content on this site is from the Sellers and the sellers are responsible for the accuracy of the prices and content they provide.  All other content is Copyright Avetti.com Corporation.</p>

<p>You can rate sellers on our site and the OpenForBusinessMarketplace may decide not to post or remove your review if the content is not appropriate,  or respectful or for any reason. Sellers that attempt to rate themselves may be banned from the site.</p>

<p>For all products are listed  shoppers can transact an offer to purchase.  The seller can accept or decline the offer.  Some products will be sold via credit card payment and this may be to the sellers credit card payment processor. If pricing or product information on the site is incorrect a seller may decline an order.</p>

<p>Sellers cannot post products that are illegal or inappropriate to the categories on the site.  The OpenForBusinessMarketplace may remove any listing at any time and ban any seller at any time for any reason.</p>

<p>We may provide links to the Sellers own website.  We are not responsible for any content linked from our site or data or  images seller upload.</p>

<p>If you see content that is inappropriate or if you have any questions about our policies please contact us in the contact us section of the OpenForBusinessMarketplace site.</p></div>`;

export const contactUs = `<div class="row">

<div class="row">
      <div style="text-align: center">
          <h2 class="formHead">CONTACT THE OPENFORBUSINESS MARKETPLACE</h2>
      </div>
      </div>
     
      <div class="col-lg-12">
          <div class="card-panel">
              <h4 class="h4-responsive">Send an Email:</h4>
              <base target="_parent" />
              
              
              
             
              
              <form name="contactForm" method="post" action="https://c95prod.avetti.ca/preview/acsitenew/contactus/"  >
                  <table style="padding: 10px 6px 12px 10px; font-size: 0.9375em; width: 100%; background-position: 50% 0%; background-repeat: repeat-x;">
                      <tbody>
                      <tr style="color: #0069e1; font-family: Arial,Verdana,Helvetica,sans-serif; font-size: 1.125em; font-weight: bold; margin-bottom: 0px; margin-top: 0px;" align="center">
                          <td colspan="4">
                          </td>
                      </tr>
                      <tr style="color: #0069e1; font-family: Arial,Verdana,Helvetica,sans-serif; font-size: 0.125em; font-weight: normal; margin-bottom: 0px; margin-top: 0px;" align="center">
                          <td colspan="4"> </td>
                      </tr>
                      <tr style="color: #0069e1; font-family: Arial,Verdana,Helvetica,sans-serif; font-size: 0.9375em; font-weight: normal; margin-bottom: 0px; margin-top: 0px;" align="left">
                      </tr>
                      <tr style="color: #0069e1; font-family: Arial,Verdana,Helvetica,sans-serif; font-size: 0.5em; font-weight: normal; margin-bottom: 0px; margin-top: 0px;" align="center">
                          <td colspan="4"> </td>
                      </tr>
                      <tr>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span>First Name: <span class="required" style="color: #ff0000;">*</span></span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span><input id="insightly_firstName" type="text" name="FirstName" class="contactInput" /></span></td>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span> </span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span> </span></td>
                      </tr>
                      <tr>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span>Last Name: <span class="required" style="color: #ff0000;">*</span></span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span><input id="insightly_lastName" type="text" name="LastName" class="contactInput" /></span></td>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span> </span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span> </span></td>
                  </tr>
                      <tr>
                       <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span>Location: <span style="color: #ff0000;">*</span>
                     </span></td>
                        <td style="font-size: 0.9375em; font-weight: normal;" width="10%">
                              <select id="country" name="country" class="contactInput" style="display:block !important;">
                                  <option selected>Select Location</option>
                                  <option value="Afganistan">Afghanistan</option>
                                  <option value="Albania">Albania</option>
                                  <option value="Algeria">Algeria</option>
                                  <option value="American Samoa">American Samoa</option>
                                  <option value="Andorra">Andorra</option>
                                  <option value="Angola">Angola</option>
                                  <option value="Anguilla">Anguilla</option>
                                  <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                  <option value="Argentina">Argentina</option>
                                  <option value="Armenia">Armenia</option>
                                  <option value="Aruba">Aruba</option>
                                  <option value="Australia">Australia</option>
                                  <option value="Austria">Austria</option>
                                  <option value="Azerbaijan">Azerbaijan</option>
                                  <option value="Bahamas">Bahamas</option>
                                  <option value="Bahrain">Bahrain</option>
                                  <option value="Bangladesh">Bangladesh</option>
                                  <option value="Barbados">Barbados</option>
                                  <option value="Belarus">Belarus</option>
                                  <option value="Belgium">Belgium</option>
                                  <option value="Belize">Belize</option>
                                  <option value="Benin">Benin</option>
                                  <option value="Bermuda">Bermuda</option>
                                  <option value="Bhutan">Bhutan</option>
                                  <option value="Bolivia">Bolivia</option>
                                  <option value="Bonaire">Bonaire</option>
                                  <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                  <option value="Botswana">Botswana</option>
                                  <option value="Brazil">Brazil</option>
                                  <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                  <option value="Brunei">Brunei</option>
                                  <option value="Bulgaria">Bulgaria</option>
                                  <option value="Burkina Faso">Burkina Faso</option>
                                  <option value="Burundi">Burundi</option>
                                  <option value="Cambodia">Cambodia</option>
                                  <option value="Cameroon">Cameroon</option>
                                  <option value="Canada" >Canada</option>
                                  <option value="Canary Islands">Canary Islands</option>
                                  <option value="Cape Verde">Cape Verde</option>
                                  <option value="Cayman Islands">Cayman Islands</option>
                                  <option value="Central African Republic">Central African Republic</option>
                                  <option value="Chad">Chad</option>
                                  <option value="Channel Islands">Channel Islands</option>
                                  <option value="Chile">Chile</option>
                                  <option value="China">China</option>
                                  <option value="Christmas Island">Christmas Island</option>
                                  <option value="Cocos Island">Cocos Island</option>
                                  <option value="Colombia">Colombia</option>
                                  <option value="Comoros">Comoros</option>
                                  <option value="Congo">Congo</option>
                                  <option value="Cook Islands">Cook Islands</option>
                                  <option value="Costa Rica">Costa Rica</option>
                                  <option value="Cote DIvoire">Cote DIvoire</option>
                                  <option value="Croatia">Croatia</option>
                                  <option value="Cuba">Cuba</option>
                                  <option value="Curaco">Curacao</option>
                                  <option value="Cyprus">Cyprus</option>
                                  <option value="Czech Republic">Czech Republic</option>
                                  <option value="Denmark">Denmark</option>
                                  <option value="Djibouti">Djibouti</option>
                                  <option value="Dominica">Dominica</option>
                                  <option value="Dominican Republic">Dominican Republic</option>
                                  <option value="East Timor">East Timor</option>
                                  <option value="Ecuador">Ecuador</option>
                                  <option value="Egypt">Egypt</option>
                                  <option value="El Salvador">El Salvador</option>
                                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                                  <option value="Eritrea">Eritrea</option>
                                  <option value="Estonia">Estonia</option>
                                  <option value="Ethiopia">Ethiopia</option>
                                  <option value="Falkland Islands">Falkland Islands</option>
                                  <option value="Faroe Islands">Faroe Islands</option>
                                  <option value="Fiji">Fiji</option>
                                  <option value="Finland">Finland</option>
                                  <option value="France">France</option>
                                  <option value="French Guiana">French Guiana</option>
                                  <option value="French Polynesia">French Polynesia</option>
                                  <option value="French Southern Ter">French Southern Ter</option>
                                  <option value="Gabon">Gabon</option>
                                  <option value="Gambia">Gambia</option>
                                  <option value="Georgia">Georgia</option>
                                  <option value="Germany">Germany</option>
                                  <option value="Ghana">Ghana</option>
                                  <option value="Gibraltar">Gibraltar</option>
                                  <option value="Great Britain">Great Britain</option>
                                  <option value="Greece">Greece</option>
                                  <option value="Greenland">Greenland</option>
                                  <option value="Grenada">Grenada</option>
                                  <option value="Guadeloupe">Guadeloupe</option>
                                  <option value="Guam">Guam</option>
                                  <option value="Guatemala">Guatemala</option>
                                  <option value="Guinea">Guinea</option>
                                  <option value="Guyana">Guyana</option>
                                  <option value="Haiti">Haiti</option>
                                  <option value="Hawaii">Hawaii</option>
                                  <option value="Honduras">Honduras</option>
                                  <option value="Hong Kong">Hong Kong</option>
                                  <option value="Hungary">Hungary</option>
                                  <option value="Iceland">Iceland</option>
                                  <option value="Indonesia">Indonesia</option>
                                  <option value="India">India</option>
                                  <option value="Iran">Iran</option>
                                  <option value="Iraq">Iraq</option>
                                  <option value="Ireland">Ireland</option>
                                  <option value="Isle of Man">Isle of Man</option>
                                  <option value="Israel">Israel</option>
                                  <option value="Italy">Italy</option>
                                  <option value="Jamaica">Jamaica</option>
                                  <option value="Japan">Japan</option>
                                  <option value="Jordan">Jordan</option>
                                  <option value="Kazakhstan">Kazakhstan</option>
                                  <option value="Kenya">Kenya</option>
                                  <option value="Kiribati">Kiribati</option>
                                  <option value="Korea North">Korea North</option>
                                  <option value="Korea Sout">Korea South</option>
                                  <option value="Kuwait">Kuwait</option>
                                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                                  <option value="Laos">Laos</option>
                                  <option value="Latvia">Latvia</option>
                                  <option value="Lebanon">Lebanon</option>
                                  <option value="Lesotho">Lesotho</option>
                                  <option value="Liberia">Liberia</option>
                                  <option value="Libya">Libya</option>
                                  <option value="Liechtenstein">Liechtenstein</option>
                                  <option value="Lithuania">Lithuania</option>
                                  <option value="Luxembourg">Luxembourg</option>
                                  <option value="Macau">Macau</option>
                                  <option value="Macedonia">Macedonia</option>
                                  <option value="Madagascar">Madagascar</option>
                                  <option value="Malaysia">Malaysia</option>
                                  <option value="Malawi">Malawi</option>
                                  <option value="Maldives">Maldives</option>
                                  <option value="Mali">Mali</option>
                                  <option value="Malta">Malta</option>
                                  <option value="Marshall Islands">Marshall Islands</option>
                                  <option value="Martinique">Martinique</option>
                                  <option value="Mauritania">Mauritania</option>
                                  <option value="Mauritius">Mauritius</option>
                                  <option value="Mayotte">Mayotte</option>
                                  <option value="Mexico">Mexico</option>
                                  <option value="Midway Islands">Midway Islands</option>
                                  <option value="Moldova">Moldova</option>
                                  <option value="Monaco">Monaco</option>
                                  <option value="Mongolia">Mongolia</option>
                                  <option value="Montserrat">Montserrat</option>
                                  <option value="Morocco">Morocco</option>
                                  <option value="Mozambique">Mozambique</option>
                                  <option value="Myanmar">Myanmar</option>
                                  <option value="Nambia">Nambia</option>
                                  <option value="Nauru">Nauru</option>
                                  <option value="Nepal">Nepal</option>
                                  <option value="Netherland Antilles">Netherland Antilles</option>
                                  <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                  <option value="Nevis">Nevis</option>
                                  <option value="New Caledonia">New Caledonia</option>
                                  <option value="New Zealand">New Zealand</option>
                                  <option value="Nicaragua">Nicaragua</option>
                                  <option value="Niger">Niger</option>
                                  <option value="Nigeria">Nigeria</option>
                                  <option value="Niue">Niue</option>
                                  <option value="Norfolk Island">Norfolk Island</option>
                                  <option value="Norway">Norway</option>
                                  <option value="Oman">Oman</option>
                                  <option value="Pakistan">Pakistan</option>
                                  <option value="Palau Island">Palau Island</option>
                                  <option value="Palestine">Palestine</option>
                                  <option value="Panama">Panama</option>
                                  <option value="Papua New Guinea">Papua New Guinea</option>
                                  <option value="Paraguay">Paraguay</option>
                                  <option value="Peru">Peru</option>
                                  <option value="Phillipines">Philippines</option>
                                  <option value="Pitcairn Island">Pitcairn Island</option>
                                  <option value="Poland">Poland</option>
                                  <option value="Portugal">Portugal</option>
                                  <option value="Puerto Rico">Puerto Rico</option>
                                  <option value="Qatar">Qatar</option>
                                  <option value="Republic of Montenegro">Republic of Montenegro</option>
                                  <option value="Republic of Serbia">Republic of Serbia</option>
                                  <option value="Reunion">Reunion</option>
                                  <option value="Romania">Romania</option>
                                  <option value="Russia">Russia</option>
                                  <option value="Rwanda">Rwanda</option>
                                  <option value="St Barthelemy">St Barthelemy</option>
                                  <option value="St Eustatius">St Eustatius</option>
                                  <option value="St Helena">St Helena</option>
                                  <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                  <option value="St Lucia">St Lucia</option>
                                  <option value="St Maarten">St Maarten</option>
                                  <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                  <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                  <option value="Saipan">Saipan</option>
                                  <option value="Samoa">Samoa</option>
                                  <option value="Samoa American">Samoa American</option>
                                  <option value="San Marino">San Marino</option>
                                  <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                  <option value="Saudi Arabia">Saudi Arabia</option>
                                  <option value="Senegal">Senegal</option>
                                  <option value="Seychelles">Seychelles</option>
                                  <option value="Sierra Leone">Sierra Leone</option>
                                  <option value="Singapore">Singapore</option>
                                  <option value="Slovakia">Slovakia</option>
                                  <option value="Slovenia">Slovenia</option>
                                  <option value="Solomon Islands">Solomon Islands</option>
                                  <option value="Somalia">Somalia</option>
                                  <option value="South Africa">South Africa</option>
                                  <option value="Spain">Spain</option>
                                  <option value="Sri Lanka">Sri Lanka</option>
                                  <option value="Sudan">Sudan</option>
                                  <option value="Suriname">Suriname</option>
                                  <option value="Swaziland">Swaziland</option>
                                  <option value="Sweden">Sweden</option>
                                  <option value="Switzerland">Switzerland</option>
                                  <option value="Syria">Syria</option>
                                  <option value="Tahiti">Tahiti</option>
                                  <option value="Taiwan">Taiwan</option>
                                  <option value="Tajikistan">Tajikistan</option>
                                  <option value="Tanzania">Tanzania</option>
                                  <option value="Thailand">Thailand</option>
                                  <option value="Togo">Togo</option>
                                  <option value="Tokelau">Tokelau</option>
                                  <option value="Tonga">Tonga</option>
                                  <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                  <option value="Tunisia">Tunisia</option>
                                  <option value="Turkey">Turkey</option>
                                  <option value="Turkmenistan">Turkmenistan</option>
                                  <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                  <option value="Tuvalu">Tuvalu</option>
                                  <option value="Uganda">Uganda</option>
                                  <option value="United Kingdom">United Kingdom</option>
                                  <option value="Ukraine">Ukraine</option>
                                  <option value="United Arab Erimates">United Arab Emirates</option>
                                  <option value="United States of America">United States of America</option>
                                  <option value="Uraguay">Uruguay</option>
                                  <option value="Uzbekistan">Uzbekistan</option>
                                  <option value="Vanuatu">Vanuatu</option>
                                  <option value="Vatican City State">Vatican City State</option>
                                  <option value="Venezuela">Venezuela</option>
                                  <option value="Vietnam">Vietnam</option>
                                  <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                  <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                  <option value="Wake Island">Wake Island</option>
                                  <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                  <option value="Yemen">Yemen</option>
                                  <option value="Zaire">Zaire</option>
                                  <option value="Zambia">Zambia</option>
                                  <option value="Zimbabwe">Zimbabwe</option>
                                </select> 
                            <td style="font-size: 0.9375em; font-weight: normal;" width="85%">
      
                      
                         </td> 
                      </tr>
                      <tr>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%">
                          <span>Phone: <span class="required" style="color: #ff0000;">*</span></span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span><input id="insightly_Phone" type="text" name="phone" class="contactInput" /></span></td>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span> </span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span> </span></td>
                      </tr>
                      <tr>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span>Email Address: <span class="required" style="color: #ff0000;">*</span></span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span><input id="insightly_Email" type="text" name="email"  class="contactInput" /></span></td>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span> </span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span> </span></td>
                      </tr>
                      <tr>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span>Message: <span class="required" style="color: #ff0000;">*</span></span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span id="sugarslot"><textarea id="message" type="text" name="message" class="contacttextarea" required/> </textarea></span></td>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span> </span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span> </span></td>
                      </tr>
                      <tr style="color: #0069e1; font-family: Arial,Verdana,Helvetica,sans-serif; font-size: 1.125em; font-weight: bold; margin-bottom: 0px; margin-top: 0px;" align="center">
                          <td colspan="4"> </td>
                      </tr>
                      <tr align="left">
                          <td colspan="10">
                          
                          
                             
                              <input class="btn btn-default cafe-button" style="background-color: #217730 !important; color:#fff !important;" id="contact-continue-secure" type="submit" name="Submit" value="Submit" />
                          </td>
                      </tr>
                      
                      </tbody>
                  </table>
                  
                 
                          
                         
                          
              </form>
             
      
              <script>

             
              function submit_form(){
                  if(typeof(validateCaptchaAndSubmit)!='undefined'){
                      validateCaptchaAndSubmit();
                  }else{
                      check_webtolead_fields();
                  }
              }
              function check_webtolead_fields(){
                  if(document.getElementById('bool_id') != null){
                      var reqs=document.getElementById('bool_id').value;
                      bools = reqs.substring(0,reqs.lastIndexOf(';'));
                      var bool_fields = new Array();
                      var bool_fields = bools.split(';');
                      nbr_fields = bool_fields.length;
                      for(var i=0;i<nbr_fields;i++){
                          if(document.getElementById(bool_fields[i]).value == 'on'){
                              document.getElementById(bool_fields[i]).value = 1;
                          }
                          else{
                              document.getElementById(bool_fields[i]).value = 0;
                          }
                      }
                  }
                  if(document.getElementById('req_id') != null){
                      var reqs=document.getElementById('req_id').value;
                      reqs = reqs.substring(0,reqs.lastIndexOf(';'));
                      var req_fields = new Array();
                      var req_fields = reqs.split(';');
                      nbr_fields = req_fields.length;
                      var req = true;
                      for(var i=0;i<nbr_fields;i++){
                          if(document.getElementById(req_fields[i]).value.length <=0 || document.getElementById(req_fields[i]).value==0){
                              req = false;
                              break;
                          }
                      }
                      if(req){
                          document.WebToLeadForm.submit();
                          return true;
                      }
                      else{
                          alert('Please provide all the required fields');
                          return false;
                      }
                      return false
                  }
                  else{
                      document.WebToLeadForm.submit();
                  }
              }
             
              
                  $("[name='contactForm']").submit(function() {
                      if ($.trim($("#insightly_firstName").val()) === "" || 
                          $.trim($("#insightly_lastName").val()) === "" ||
                          $.trim($("#country").val()) === "" ||
                          $.trim($("#insightly_Phone").val()) === "" ||
                          $.trim($("#insightly_Email").val()) === "" ||
                          $.trim($("#message").val()) === "") {
                          
                          alert('Please, fill all the fields before submitting');
                          return false;
                          
                      } 
                      else {
                          $('#contactThankYouBack').removeClass('hidden'); return false;
                      }
                  });
              
              </script>
      
      <div id="contactThankYouBack" class="hidden">
      <div id="contactThankYouContain">
        <div id="contactThankYouHead">
            <a href="javascript:void(0);" class="pull-right" onclick="$('#contactThankYouBack').addClass('hidden');">X</a>
            <div class="clearfix"></div>
        </div>
        <div id="contactThankYouBody">
            <p style="font-size: 1.3125em; text-align: center; line-height: 25px; margin-bottom: 15px; margin-top: 15px; font-weight: 500;">Thank you for your interest in <br/>The OpenForBusiness Marketplace.</p>
      
            <p style="font-size: 1em; text-align: center; line-height: 23px; margin-bottom: 25px;">We will respond to your query via the e-mail address you provided.</p>
        </div>
        <div id="contactThankYouFoot">
            <a href="javascript:void(0);" class="btn btn-default" onclick="window.location.reload();
            ">Close</a>
        </div>
      </div>
      </div>




</div>
</div>

<div>
        <div class="col-lg-12">           

            <div>
            
                <div class="col-md-3" style="padding-left:5px; padding-right:5px;">
                    <div class="card-panel c-panel-2 primary-contact-card" style="position: relative;    padding: 23px 13px; font-weight:normal;">
                        <div class="card-panel-headlines">
                            <h4 class="">Avetti.com Corporation</h4>
                            <p style="font-size: 1em !important;"><span>Canada</span></p>
                            <br/>
                        </div>
                        
                        <div class="card-panel-info">
                            <p>92 Caplan Avenue, Suite 206</p>
                            <p>Barrie, Ontario, L4N 9J2, Canada</p>
                            <br/>
                            <br/><br/><br/><br/>
                            <p><span>Phone:</span> +1 705 797 2105</p>
                            <p><span>Hours:</span> 9:30am - 5:30pm EST/EDT</p>                            
                        </div>
                        <p style="position:absolute;bottom:0;line-height: 24px;"><span>E-Mail:</span> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;" href="mailto:ecommerce@avetti.com">ecommerce@avetti.com</a><br/>  <span>Directions:</span> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  target="_blank" href="https://www.google.com/maps/dir/Current+Location/Avetti.com+Corporation,+92+Caplan+Ave+%23206,+Barrie,+ON+L4N+0Z7,+Canada/@44.3384041,-7">Click Here</a></p>
                        
                    </div>
                </div>
                
                <div class="col-md-3" style="padding-left:5px; padding-right:5px;">
                    <div class="card-panel c-panel-2" style="position: relative; padding: 23px 13px;">
                        <div class="card-panel-headlines">
                            <h4 class="">Avetti <br/>MENA</h4>
                            <p style="font-size: 1em !important;"><span>UAE</span></p>
                            <br/>
                        </div>
                        
                        <div class="card-panel-info">
                            <p>Level 7, Bay Square, Building 11</p>
                            <p>Business Bay, Dubai,UAE</p>
                            
                            <br/>
                            <br/><br/><br/><br/>
                            <p><span>Phone:</span> +971 04 556 1567</p>
                            <p><span>Hours:</span> 9am - 5pm GST</p>
                            
                        </div>
                        
                        <p style="position:absolute;bottom:0;line-height: 24px;"><span>E-Mail:</span> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  href="mailto:ecommerce@avetti.com">ecommerce@avetti.com</a><br/> <span>Directions:</span> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  target="_blank" href="https://www.google.com/maps/dir/Current+Location/Bay+Square+Building+11/@25.1852519,55.2790726,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f68334f3afdc7:0x29a00b6670b297d6!8m2!3d25.1852519!4d55.2812613">Click Here</a></p>
                        
                    </div>
                </div>
                
                <div class="col-md-3" style="padding-left:5px; padding-right:5px;">
                    <div class="card-panel c-panel-2" style="position: relative; padding: 23px 13px;">
                        <div class="card-panel-headlines">
                            <h4 class="">Avetti Commerce Pvt Ltd.</h4>
                            <p style="font-size: 1em !important;"><span>India</span></p>
                            <br/>
                        </div>
                        
                        <div class="card-panel-info">
                            <p style="line-height: 24px;">H-No 1-62/K/36, 3rd Floor, Plot No:36, Spaces & More Business Park, Kavuri Hills Phase-1, Hyderabad Telangana, India 500081</p>
                            
                            <br/>
                            <p><span>Phone:</span> +91 040-42308877</p>
                            <p><span>Hours:</span> 10:30 am - 7:30 pm IST</p>
                            
                        </div>
                        <p style="position:absolute;bottom:0;line-height: 24px;"><span>E-Mail:</span> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  href="mailto:ecommerceindia@avetti.com">ecommerceindia@avetti.com</a><br/>  <span>Directions:</span> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  target="_blank" href="https://www.google.com/maps/dir/Current+Location/Spaces+%26+More+Business+Center+@+Jubilee+Hills/@17.4377095,78.3985151,17z/data=!3m1!4b1!4m5!3m4!1s0x3bcb914619652db9:0x6017767f481c36d2!8m2!3d17.4377095!4d78.4007038">Click Here</a></p>
                        
                    </div>
                </div>
                <div class="col-md-3" style="padding-left:5px; padding-right:5px;">
                    <div class="card-panel c-panel-2" style="position: relative; padding: 23px 13px;">
                        <div class="card-panel-headlines">
                            <h4 class="">Avetti Global Services</h4>
                            <p style="font-size: 1em !important;"><span>Panama</span></p>
                            <br/>
                        </div>
                        
                        <div class="card-panel-info">
                            <p style="line-height: 24px;">Boulevard Panama Pacifico International Business Park Edificio 3825, Oficina 101B Panama Pacifico, Panama</p>
                            <br/>
                            <p><span>Phone:</span> +507 834 7000</p>
                            <p><span>Hours:</span> 9:30a.m. - 5:30 p.m. EST</p>
                            
                        </div>
                        <p style="position:absolute;bottom:0;line-height: 24px;"><span>E-Mail:</span> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  href="mailto:ecommercelatam@avetti.com">ecommercelatam@avetti.com</a><br/>  <span>Directions:</span> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  target="_blank" href="https://www.google.com/maps/dir/Current+Location/International+Business+Park+Edificio+3825/@8.9275638,-79.5946535,17z/data=!4m8!1m2!2m1!1sInternational+Business+Park+Edificio+3825,+Oficina+101B+Panama+Pacifico,+Panama!3m4!1s0x8faca5ba21c656f7:0x6a5c6200447be59a!8m2!3d8.9273945!4d-79.5923633">Click Here</a></p>
                        
                    </div>
                </div>
                
            </div>

            


        </div>

    </div>`;

export const sellerThankYou = `<div class="row staticContent"><h1>THANK YOU FOR SIGNING UP!</h1>
    
    <p>The first step is that we need information about your products.</p>

<p>We accept a simple spreadsheet format and you can find the sample spreadsheet and a video explaining how to add your data at the link below. The video will also explain how to share your images into a dropbox account and how to name them. You can send us 100 products with up to 500 variants. If you only have a few products you can just email the images to us at <a href="mailto:marianae@avetti.ca">marianae@avetti.ca</a>  or <a href="mailto:davids@avetti.ca">davids@avetti.ca</a>  </p>

<p>Here is the link with the video instructions and spreadsheet:</p>

<p><a href="https://www.openforbusinessmarketplace.ca/setup/">https://www.openforbusinessmarketplace.ca/setup/</a>

<p>You can start this right away or you can wait after we have a call. We prefer to use zoom to have a meeting. If you can review the spreadsheet before the call that would be best. If not, we'll explain the process during the zoom call.</p>

<p>Click this link <a href="https://app.hubspot.com/meetings/davids4">https://app.hubspot.com/meetings/davids4</a> to schedule a time to talk to us.</p>

<p>If you can’t do Zoom please advise in the form. If you close this page and forget to schedule a meeting, we’ll email you.</p>

<p>If this is all just too confusing email us at <a href="mailto:ecommerce@avetti.com">ecommerce@avetti.com</a>   or leave a message at <a href="tel:17057972105">705 797 2105</a></p>

<p>Thank you.</p></div>`;
