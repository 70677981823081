import React from "react";

import slide1 from "../assets/img/fr-dl-slider.png";

import styles from "./slide.module.css";
import { Link } from "@reach/router";

const HomeCarousel = () => {
  return (
    <div className="carousel-wrapper">
      <div className={styles.slide}>
        <img src={slide1} alt="CHÉQUES HAUTE SÉCURITÉ" />
        <div className={styles.textWrapper}>
          <h1>Les chèques d'affaires les plus sûrs offerts par Deluxe</h1>
          <p>
            Les couches successives de protection contre la fraude de ces
            cheques poussent les escrocs á cibler quelqu'un d'autre.
          </p>
          <Link to="/gerez/cheques/haute-securite/cheques-laser/">
            <button>J'ACHÉTE DES CHÉQUES HAUTE SÉCURITÉ</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeCarousel;
