import React from "react";

import StaticContent from "../components/AC-StaticPages/StaticContent.jsx";

import HomeCarousel from "./HomeCarousel";

function Main(props) {
  return (
    <div id="bd">
      <div className="item">
        <HomeCarousel />
      </div>
    </div>
  );
}

export default Main;
